
import { Fragment } from 'vue-frag'
import { CurrentView } from '~/store/building/-constants'

export default {
  name: 'OrganismProjectUI',
  components: { Fragment },
  computed: {
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    }
  }
}
